import "./knowledge.scss"
import Icon from "../Icon";

const Knowledge = ({setShowPrepareModal}) => {
  return (
    <>
      <div className="panel knowledge">
        <div className="knowledge__content">
          <p className="knowledge__title">
            Что нужно знать перед диагностикой
          </p>
          <button className="knowledge__btn" onClick={() => setShowPrepareModal(true)}>
            Узнать
            <Icon name="angle-right" className="knowledge__btn-icon"/>
          </button>
        </div>
        <img
          src="/assets/images/knowledge.png"
          className="knowledge__img"
          alt=""
        />
      </div>
    </>
  )
}

export default Knowledge