import React, {useState} from 'react';
import Icon from "../../../components/Icon";
import cn from "classnames";
import AppointmentTable from "./components/AppointmentTable/AppointmentTable";
import {useNavigate} from "react-router-dom";
import {useUserStore} from "../../../store/user-store";
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import {useAppointmentStore} from "../../../store/appointmentStore";

function StepMakeAppointment() {
  const [result] = useAppointmentStore((state) => [state.result])
  const [setHaveAppointment] = useAppointmentStore((state) => [state.setHaveAppointment])
  const [firstName, secondName, lastName] = useUserStore((state) => [state.firstName, state.secondName, state.lastName])
  const [payment, setPayment] = useState('card')
  const navigate = useNavigate()

  const handleClick = () => {
    // TODO: отправить данные
    setHaveAppointment(true)
    if (payment === 'card') {
      navigate('/payment-result?backTo=result')
    }
    if (payment === 'cash') {
      navigate('/appointment-result')
    }
  }

  return (
    <div className="container">
      <div className="panel panel--top">
        <div className="page-appointment__top">
          <p className="page-appointment__title">Детали записи</p>

          <div className="page-appointment__data-group">
            <p className="page-appointment__data-label">ФИО</p>
            <p className="page-appointment__data-value">{lastName} {firstName} {secondName} </p>
          </div>
          <div className="page-appointment__data-group">
            <p className="page-appointment__data-label">Клиника</p>
            <p className="page-appointment__data-value">{result.clinic.name}, {result.clinic.addr}</p>
          </div>
          <div className="page-appointment__data-group">
            <p className="page-appointment__data-label">Метод коррекции</p>
            <p className="page-appointment__data-value">{result.service.name}</p>
          </div>
          <div className="page-appointment__data-group">
            <p className="page-appointment__data-label">Дата диагностики</p>
            <p className="page-appointment__data-value">{format(result.diagnosticsDate, 'd MMMM в HH:mm', { locale: ru })}</p>
          </div>
          <div className="page-appointment__data-group">
            <p className="page-appointment__data-label">Дата коррекции</p>
            <p className="page-appointment__data-value">{format(result.correctionDate, 'd MMMM', { locale: ru })}</p>
          </div>
        </div>
      </div>
      <div className="panel">
        <div className="page-appointment__title">Оплата</div>
        <div className="page-appointment__payment-methods">
          <button
            className={cn('page-appointment__payment-method', {'page-appointment__payment-method--selected': payment === 'card'})}
            onClick={() => setPayment('card')}
          >
            {payment === 'card' && (<Icon name="circle-tick-blue" className="page-appointment__payment-method-icon"/>)}
            {payment !== 'card' && (<Icon name="card" className="page-appointment__payment-method-icon"/>)}
            <span className="page-appointment__payment-method-text">Банковской картой онлайн</span>
          </button>
          <button
            className={cn('page-appointment__payment-method', {'page-appointment__payment-method--selected': payment === 'cash'})}
            onClick={() => setPayment('cash')}
          >
            {payment === 'cash' && (<Icon name="circle-tick-blue" className="page-appointment__payment-method-icon"/>)}
            {payment !== 'cash' && (<Icon name="wallet" className="page-appointment__payment-method-icon"/>)}
            <span className="page-appointment__payment-method-text">Наличными или картой в клинике</span>
          </button>
        </div>
        <AppointmentTable/>
      </div>
      <button
        className="button page-appointment__bottom-button"
        type="button"
        onClick={handleClick}
      >
        {payment === 'card' && (<>Перейти к оплате</>)}
        {payment === 'cash' && (<>Записаться</>)}
      </button>
    </div>
  );
}

export default StepMakeAppointment;